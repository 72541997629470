.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 320px;
  height: 100%;
  background: rgba(#000, .5);
}

.loader-overlay {
  background: rgba(#fff, .8);
}

.transparent-overlay {
  background: transparent;
}

.overlay-in-block {
  position: absolute;
}
