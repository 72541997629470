.input-container {
  position: relative;
  width: 100%;
  //height: 100%;
}

.input-container-clean {
  width: 100%;
  height: 100%;
  min-height: inherit;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  text-align: inherit;
  resize: none;
  background: inherit;
}

.input-new {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  line-height: 26px;
  color: #111;
  text-align: left;
  display: block;
  cursor: text;
  background: #fff;
  transition: background .2s;
  box-sizing: border-box;

  &.variant-blur {
    backdrop-filter: blur(20px);
    background: rgba(#000, .01);
  }

  &.variant-clean {
    width: 100%;
    height: 100%;
    min-height: inherit;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    text-align: inherit;
    resize: none;
    background: inherit;

    &::placeholder {
      font-size: inherit;
      font-weight: 400;
    }
  }

  &.variant-grey {
    border: 1px solid rgba(#111, .2);
    background: #fff;
  }

  &.text-color-inherit {
    color: inherit;
  }

  &.text-color-grey {
    color: #111;
  }

  &.variant-white {
    border: 1px solid #fff;
    background: #fff;
  }

  &.text-color-white {
    border: 1px solid #fff;
    color: #fff;

    &::placeholder {
      color: rgba(#fff, .5);
    }
  }

  &.text-color-red {
    color: #ee6f69;
  }

  &.is-disabled {
    opacity: .7;
  }

  &.is-error {
    border: 1px solid #ee6f69;
    color: #ee6f69;

    &::placeholder {
      color: #ee6f69;
    }
  }

  /* Sizes */
  &.size-m {
    height: 38px;
    padding: 8px 10px;
    font-size: 16px;
    line-height: 38px;

    &::placeholder {
      font-size: 16px;
    }
  }

  &.size-l {
    height: 48px;
    padding: 13px 15px;
    font-size: 18px;
    line-height: 48px;

    &::placeholder {
      font-size: 18px;
    }
  }

  &.icon-position-left {
    padding-left: 36px; // stylelint-disable-line
  }

  &.icon-position-right {
    padding-right: 36px; // stylelint-disable-line
  }

  .error-text {
    margin: 5px 0 0 17px;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    text-align: left;

    &.red {
      color: #ee6f69;
    }

    &.white {
      color: #fff;
    }
  }

  &::placeholder {
    font-size: 16px;
    color: rgba(#111, .5);
  }
}
