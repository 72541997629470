.exit-icon {
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  background: #F5F7FA;

  &:hover {
    .i-exit {
      opacity: 1;
    }

    .text-exit-icon {
      color: #111;
    }
  }

  &.light {
    background: none;

    .i-exit {
      opacity: 1;
      filter: brightness(100);
    }

    .text-exit-icon {
      color: #fff;
    }
  }
}

.link {
  width: 100%;
}

.i-exit {
  position: relative;
  width: 18px;
  height: 21px;
  margin: 7px auto 2px;
  display: inline-block;
  vertical-align: middle;
  background: url(./assets/exit.svg) 0/contain no-repeat;
  opacity: 0.5;
  transition: opacity .2s;
}

.text-exit-icon {
  width: 100%;
  font-size: 11px;
  font-weight: 700;
  line-height: 18px;
  color: #888;
  display: inline-block;
  vertical-align: top;
  transition: color .2s;
  text-transform: uppercase;
}
