.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
}

.sidebar-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1031;
  width: 100vw;
  max-width: 420px;
  height: 100%;
  visibility: hidden;
  transform: translate(-150%);
  background: #fff;
  transition: transform .4s, visibility .4s;

  &.is-show {
    visibility: visible;
    transition: transform .4s, visibility .4s;
    transform: translate(0, 0);
  }

  &.is-class {
    background: #823cdc;
  }
}

.sandwich-btn {
  position: relative;
  width: 40px;
  height: 30px;
  border: 0;
  outline: 0;
  color: inherit;
  cursor: pointer;
  background: transparent;
  opacity: 0.5;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;

    .line-sandwich {
      &:first-of-type {
        transform: rotate(35deg);
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: rotate(-35deg);
      }
    }
  }

  .line-sandwich {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 26px;
    height: 3px;
    border-radius: 2px;
    background: currentcolor;
    transform: rotate(0);
    transition: transform .2s;

    &:nth-of-type(2) {
      top: 12px;
      opacity: 1;
      transition: opacity .2s;
    }

    &:nth-of-type(3) {
      top: 19px;
    }
  }
}

.header-sidebar-account {
  height: 50px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-sidebar-class {
  padding: 0 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper-exit-icon {
  width: 77px;
}

.i-close-sidebar {
  width: 17px;
  height: 17px;
  display: block;
  opacity: 1;
  cursor: pointer;
  transition: opacity .2s;
  background: url(./assets/close.svg) 0/contain no-repeat;

  &:hover {
    opacity: .7;
  }
}

.container-logo {
  text-align: center;
  display: flex;
  justify-content: center;
}

// Sidebar Class
.tabs-sidebar {
  width: 220px;
  height: 32px;
  padding: 0;
  margin: 20px auto 0;
  border-radius: 4px;
  line-height: 32px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.tab-sidebar {
  font-size: 16px;
  line-height: 32px;
  color: #fff;
  text-align: center;
  background: rgba(#fff, .2);

  &.is-active {
    color: var(--c-brand);
    background: #fff;
  }
}

.video-call-button-wrapper {
  margin: 20px 0 0;
  text-align: center;
}