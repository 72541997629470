.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  min-width: 360px;
  max-width: 100vw;
  height: 50px;
  border-bottom: 1px solid transparent;
  color: #160330;
  background: var(--c-header);
  box-shadow: 0 1px 4px rgba(#000, .15);
  transition: .2s ease;
  transition-property: background, border-color, box-shadow;

  &:has(.i-feedback) {
    .header-icons {
      margin: 0 24px;
    }

    .wrapper-instruction {
      margin: 0 0 0 24px;
    }
  }

  &.is-fixed {
    border-color: rgba(#fff, .2);
    color: #fff;
    box-shadow: none;
    background: transparent;

    .logo-image {
      filter: brightness(0) invert(1);
    }
  }

  .lang {
    margin: 0 24px 0 0;
  }

  .logo-link {
    height: 100%;
    padding: 14.5px 0;
    display: block;

    @media (min-width: 992px) {
      padding: 17px 0;
    }
  }

  .logo-image {
    height: 100%;
    transition: filter .2s ease;
  }

  .container-logo:empty,
  .container-logo-hidden {
    display: none;
  }

  .login-btn a {
    text-decoration: none;
  }

  @media (max-width: 575px) {
    padding: 0 15px;

    .container-logo {
      margin: 0 0 0 10px;
    }

    .login-btn {
      margin: 0 10px 0 0;
    }
  }

  @media (max-width: 991px) {
    .container-logo-class {
      display: none;
    }
  }

  @media (min-width: 768px) {
    .container-sandwich-btn {
      display: none;
    }
  }

  @media (min-width: 992px) {
    height: 60px;
  }

  @media (min-width: 1300px) {
    .class-wrapper { // only for class
      width: 1290px;
    }
  }
}

.container-header {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.interaction-elements {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-icons,
.container-logo,
.header-interaction {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-icons {
  margin: 0 10px 0 0;
}

.i-feedback {
  position: relative;
  width: 23px;
  height: 22px;
  cursor: pointer;
  background: url(./assets/message.svg) no-repeat 0/contain;
  opacity: 0.5;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;

    .hint {
      visibility: visible;
      opacity: 1;
      transform: scale(1, 1) translate(-50%, 0);
    }
  }
}

.wrapper-instruction {
  margin: 0 0 0 10px;
}

.container-profile {
  height: 100%;
  cursor: pointer;
}

.wrapper-exit {
  width: 80px;
  height: 100%;
}
