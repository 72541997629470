$c_violet: #823cdc;
$c_orange: #fe8b21;

.button-vintage {
  /**
   * General styles
   */
  border: none;
  outline: 0;
  border-radius: 16px;
  font-weight: 700;
  line-height: 100%;
  text-align: center;
  display: inline-flex;
  vertical-align: middle;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  gap: 10px;
  letter-spacing: .02em;
  opacity: 1;
  flex-shrink: 0;
  transition: transform .2s, opacity .2s;
  will-change: transform, opacity;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;

  &:hover {
    opacity: .7;
    text-decoration: none;
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
    opacity: .7;
  }

  /**
   * Size styles
   */
  &.size {
    &-xs {
      padding: 8.5px 15px;
      border-radius: 8px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: .01em;
    }

    &-s {
      min-width: 140px;
      padding: 16px 32px;
      border-radius: 12px;
      font-size: 16px;
      line-height: 1;
    }

    &-m {
      min-width: 160px;
      padding: 18px 40px;
      font-size: 16px;
      line-height: 1;
    }

    &-l {
      min-width: 180px;
      padding: 24px 48px;
      font-size: 18px;
      line-height: 1;
    }
  }

  /**
   * Color styles
   */

  &.color-violet {
    &.variant {
      &-fill {
        color: #fff;
        background: $c_violet;
      }

      &-border {
        border-color: $c_violet;
        color: $c_violet;
        background: transparent;
      }
    }
  }

  &.color-orange {
    &.variant {
      &-fill {
        color: #fff;
        background: $c_orange;
      }

      &-border {
        border-color: $c_orange;
        color: $c_orange;
      }
    }
  }

  &.color-white {
    &.variant {
      &-fill {
        color: #111;
        background: #fff;
      }

      &-border {
        border-color: #fff;
        color: #fff;
      }
    }
  }

  /**
   * Text color styles
   */

  &.text-violet {
    &.variant-fill,
    &.variant-border {
      color: $c_violet;
    }
  }

  &.text-orange {
    &.variant-fill,
    &.variant-border {
      color: $c_orange;
    }
  }

  &.text-white {
    &.variant-fill,
    &.variant-border {
      color: #fff;
    }
  }

  /**
   * Variant styles
   */

  &.variant {
    &-border {
      border-width: 2px;
      border-style: solid;
      background: transparent;

      &.size-xs {
        padding: 7px 15px;
      }

      &.size-s {
        padding: 14px 32px;
      }

      &.size-m {
        padding: 16px 40px;
      }

      &.size-l {
        padding: 22px 48px;
      }
    }
  }

  /**
   * Styles for landings
   */

  &.type-landing {
    /* smaller buttons for mobile */
    &.variant-fill,
    &.variant-border {
      &.size-l {
        min-width: 160px;
        padding: 20px 40px;
        font-size: 16px;
        line-height: 1;

        // stylelint-disable-next-line max-nesting-depth
        @media screen and (min-width: 768px) {
          min-width: 180px;
          padding: 24px 48px;
          font-size: 18px;
          line-height: 1;
        }
      }
    }
  }

  &.no-shadow {
    /* Shadows */
    &.variant-fill {
      &.size-m {
        box-shadow: 0 10px 10px rgba(#000, .15);
      }

      &.size-l {
        box-shadow: 0 22px 20px rgba(#000, .15);
      }
    }
  }

  &.full-width {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
}
