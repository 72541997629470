.popup {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  overflow: auto;
  pointer-events: none;
  box-sizing: border-box;

  //Resolution 532
  @media screen and (min-width: 532px) {
    min-width: 320px;
    margin: auto;

    .wrapper-close {
      top: 20px;
      right: 20px;

      &.inner-close {
        top: 15px;
        right: 15px;
      }
    }

    .content-popup {
      width: auto;
      height: auto;
      padding: 50px;
      border-radius: 8px;
    }

    &.popup-grammar {
      .content-popup {
        width: 100%;
      }
    }
  }

  //Resolution 1000
  @media screen and (min-width: 1000px) {
    &.popup-grammar {
      .content-popup {
        width: auto;
      }
    }
  }
}

.inactive-popup {
  color: inherit;

  @media screen and (orientation: portrait), screen and (max-width: 531px) {
    width: auto;
    height: 100%;
    margin: auto;
  }
}

.wrapper-popup {
  width: 100%;
  height: 100%;
  display: flex;
}

.content-popup {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: auto;
  outline: 3px solid transparent;
  font-family: 'Open Sans', sans-serif;
  color: #111;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: auto;

  &.is-new {
    width: auto;
    height: auto;
    min-height: inherit;
    padding: 10px;
    margin: auto;
    align-items: center;

    .wrapper-close {
      position: static;
      margin: 0 0 16px;
    }

    //Resolution 768
    @media screen and (min-width: 768px) {
      .wrapper-close {
        width: 80px;
        height: 80px;
        margin: 0 0 24px;
        border: 2px solid #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media screen and (min-width: 531px) {
    height: auto;
    min-height: auto;
  }
}

.without-bg {
  background: transparent;
}

.wrapper-close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}
