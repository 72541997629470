.close-icon {
  width: 15px;
  height: 15px;
  transform: rotate(0);
  transition: transform .2s;
  background: url(./assets/close--green.svg) 0/contain no-repeat;
  cursor: pointer;

  &:hover {
    transform: rotate(90deg);
    opacity: .7;
  }

  &.white {
    background: url(./assets/close.svg) 0/contain no-repeat;
  }

  &.is-new {
    width: 16px;
    height: 16px;
    background: url(./assets/close.svg) 0/contain no-repeat;
  }

  //Resolution 760
  @media screen and (min-width: 532px) {
    width: 20px;
    height: 20px;
    background: url(./assets/close.svg) 0/contain no-repeat;

    &.green {
      background: url(./assets/close--green.svg) 0/contain no-repeat;
    }
  }

  //Resolution 1000
  @media screen and (min-width: 1000px) {
    &.green {
      background: url(./assets/close.svg) 0/contain no-repeat;
    }
  }
}
