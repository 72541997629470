.item-menu {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: 0;
  border-top: 1px solid rgba(var(--c-text-rgb), .20);
  outline: none;
  display: flex;
  align-items: center;
  opacity: 1;
  cursor: pointer;
  text-decoration: none;
  background: transparent;

  &:hover {
    .icon-container {
      opacity: 1;
    }

    .text-menu {
      color: var(--c-text);
    }
  }

  &.is-sidebar {
    height: auto;
    padding: 0;

    .text-menu {
      padding: 18px 16px;
      font-size: 18px;
      font-weight: 600;
      line-height: normal;
      color: var(--c-text);
    }
  }

  &-button-container {
    width: 100%;
    padding: 24px 16px;
  }
}

.icon-container {
  width: 20px;
  margin: 0 10px 0 0;
  display: flex;
  justify-content: center;
  opacity: 0.4;
  transition: opacity .2s;
}

.i-user {
  width: 17px;
  height: 18px;
  background: url(./assets/user.svg) 0/contain no-repeat;
}

.i-message {
  width: 20px;
  height: 18px;
  background: url(./assets/message.svg) 0/contain no-repeat;
}

.i-exit {
  width: 18px;
  height: 18px;
  background: url(./assets/exit.svg) 0/contain no-repeat;
}

.text-menu {
  font-size: 14px;
  color: #888;
  transition: color .2s;
}
