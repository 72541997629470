.popup {
  height: 100%;
  border-radius: 6px;

  .wrap {
    position: relative;
    height: 100%;
    padding: 21px 40px 40px;
    background: #fff;

    //Min Resolution 532
    @media screen and (min-width: 532px) {
      width: 360px;
      border-radius: 8px;
    }

    //Max Resolution 532
    @media screen and (max-width: 532px) {
      height: 100vh;

      .wrap {
        height: 100%;
      }
    }
  }

  .title {
    margin: 0 0 13px;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 25px;
    color: #111;
    text-align: center;
  }

  .img {
    width: 80px;
    height: 80px;
    margin: 0 auto 13px;
    display: block;
    background: url(./assets/feed.svg) no-repeat 0/contain;
  }

  .img-success {
    width: 80px;
    height: 80px;
    margin: 0 auto 13px;
    display: block;
    background: url(./assets/success.svg) no-repeat 0/contain;
  }

  .help-email,
  .help-skype {
    position: relative;
    padding: 0 0 0 21px;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    color: #4fc87b;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    letter-spacing: .02em;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
    }
  }

  .help-email {
    margin: 0 0 9px;

    &:before {
      top: 50%;
      width: 14px;
      height: 10px;
      margin: -5px 0 0;
      background: url(./assets/email.svg) no-repeat 0/contain;
    }
  }

  .help-skype {
    margin: 0 0 16px;

    &:before {
      top: 50%;
      width: 16px;
      height: 16px;
      margin: -8px 0 0;
      background: url(./assets/skype.svg) no-repeat 0/contain;
    }
  }

  .desc {
    margin: 0 0 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 21px;
    color: #111;
    text-align: center;
    opacity: .7;
  }

  .input-wrap {
    margin: 0 0 20px;
  }

  .input {
    height: 178px;
    padding: 10px;
    border: 1px solid #d3dce5;
    text-align: left;
  }

  .success-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    display: none;
    align-items: center;
    justify-content: center;
    background: #fff;
    flex-flow: column;

    &.is-active {
      display: flex;
    }

    .img {
      margin: 0 0 14px;
      background: url(./assets/feed-success.svg) no-repeat 0/contain;
    }

    .title {
      margin: 0 0 4px;
    }

    .desc {
      margin: 0 0 8px;
    }

    .email {
      position: relative;
      padding: 0 0 0 21px;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 19px;
      color: rgba(var(--c-text-rgb), .7);
      text-align: center;
      display: flex;
      align-items: center;
      text-decoration: none;
      letter-spacing: .02em;

      &:before {
        width: 14px;
        height: 10px;
        margin: 0 5px;
        content: '';
        background: url(./assets/email-g.svg) no-repeat 0/contain;
      }
    }
  }
}
