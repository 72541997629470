.hint {
  position: absolute;
  left: 50%;
  bottom: 100%;
  z-index: 1030;
  padding: 5px 15px;
  margin: 0 0 7px;
  border-radius: 4px;
  font-size: 14px;
  line-height: normal;
  display: block;
  visibility: hidden;
  opacity: 0;
  white-space: nowrap;
  background: #5d6f75;
  transform-origin: left;
  transform: perspective(1px) translateX(-50%) translateY(0) translateZ(0);
  transition: opacity 200ms, visibility 200ms, transform 200ms;

  &:before {
    position: absolute;
    top: auto;
    left: 50%;
    bottom: -4px;
    margin: 0 0 0 -4.5px;
    border-width: 4px 4px 0;
    border-style: solid;
    border-color: #5d6f75 transparent;
    content: ' ';
  }

  &.light {
    background-color: #eeeff0;

    &:before {
      border-color: #eeeff0 transparent;
    }
  }

  &.right {
    left: -35px;

    &:before {
      left: 81%;
    }
  }

  &.bottom-tooltip {
    top: 100%;
    bottom: auto;
    margin: 7px 0 0;

    &:before {
      top: -4px;
      bottom: auto;
      border-width: 0 4px 4px;
    }
  }

  &.bottom-left-tooltip {
    top: 100%;
    bottom: auto;
    margin: 7px 0 0;

    &:before {
      top: -4px;
      left: 90%;
      bottom: auto;
      border-width: 0 4px 4px;
    }
  }

  &.small {
    //font-size: 12px;
    text-transform: none;
  }

  &.middle {
    width: 190px;
    white-space: normal;
  }

  &.wrap {
    font-weight: 400;
    text-transform: initial;
    white-space: normal;
  }

  &.visible {
    display: block;
    visibility: visible;
    opacity: 1;
  }

  &.word-hint {
    width: 300px;
    padding: 10px 20px 15px;
    white-space: normal;

    .text-hint {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &.left-tooltip {
    left: 0;
    transform: perspective(1px) translateX(0) translateY(0) translateZ(0);

    &:before {
      left: 9px;
    }
  }

  &.green {
    background: #4fc87a;

    &:before {
      border-color: #4fc87a transparent;
    }
  }

  //Resolution 1000
  @media screen and (max-width: 1000px) {
    display: none;

    &.word-hint {
      left: 120px;
      display: block;

      &:before {
        left: 40px;
      }
    }
  }
}

.text-hint { // stylelint-disable
  font-style: normal;
  line-height: 19px;
  color: #fff;

  &.no-selection {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
}
