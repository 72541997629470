.collapse {
  color: inherit;

  &-not-active {
    &-original {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transition: opacity .8s linear, max-height .8s linear;
    }

    &-start {
      max-height: 0;
      opacity: 0;
    }
  }

  &-active {
    &-original {
      max-height: 0;
      overflow: hidden;
      transition: max-height .8s linear;
    }

    &-start {
      max-height: 2000px;
    }
  }
}

.scale {
  position: fixed;
  top: 0;
  z-index: 1031;
  width: 100%;
  height: 100%;
  transition: transform .4s;

  &-not-active {
    &-original {
      transform: scale(1);
    }

    &-start {
      transform: scale(0);
    }
  }

  &-active {
    &-original {
      transform: scale(0);
    }

    &-start {
      transform: scale(1);
    }
  }
}

.fade {
  position: relative;
  z-index: 1030;
  transition: opacity .4s;

  &-not-active {
    &-original {
      opacity: 1;
    }

    &-start {
      opacity: 0;
    }
  }

  &-active {
    &-original {
      opacity: 0;
    }

    &-start {
      opacity: 1;
    }
  }
}

.moment-fade {
  position: relative;
  z-index: 1030;
  transition: opacity 0s;

  &-not-active {
    &-original {
      opacity: 1;
    }

    &-start {
      opacity: 0;
    }
  }

  &-active {
    &-original {
      opacity: 0;
    }

    &-start {
      opacity: 1;
    }
  }
}

.fade-in-top {
  position: relative;
  transition: opacity .4s, transform .4s;

  &-not-active {
    &-original {
      transform: translateY(0);
      opacity: 1;
    }

    &-start {
      transform: translateY(-20px);
      opacity: 0;
    }
  }

  &-active {
    &-original {
      transform: translateY(-20px);
      opacity: 0;
    }

    &-start {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
