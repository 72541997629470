.logo {
  margin: auto;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.img-logo {
  display: block;
}
