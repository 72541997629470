$c_green: #4fc87a;
$c_orange: #ff6a21;

.button-new {
  width: 100%;
  border: none;
  outline: 0;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #fff;
  text-align: center;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  gap: 5px;
  letter-spacing: .5px;
  text-transform: uppercase;
  opacity: 1;
  cursor: pointer;
  box-sizing: border-box;
  transition: background .2s, color .2s, opacity .2s;

  &:active,
  &:hover {
    color: #fff;
    opacity: .7;
    text-decoration: none;
  }

  &.is-disabled {
    opacity: .7;
    cursor: default;
    pointer-events: none;
  }

  /* Sizes */
  &.size-l {
    height: 48px;
    padding: 0 30px;
    font-size: 18px;
    line-height: 48px;
  }

  &.size-m {
    height: 38px;
    padding: 0 30px;
    font-size: 14px;
    line-height: 38px;
  }

  &.size-s {
    height: 24px;
    padding: 0 10px;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: .18px;
  }

  /* Colors */
  &.green {
    &.fill {
      background: $c_green;

      &:active,
      &:hover {
        background: darken($c_green, 10%);
        opacity: 1;
      }
    }

    &.border {
      color: $c_green;

      &:active,
      &:hover {
        border: 1px solid darken($c_green, 10%);
        color: darken($c_green, 10%);
        opacity: 1;
      }
    }

    &.underline {
      color: $c_green;

      &:active,
      &:hover {
        color: darken($c_green, 10%);
        opacity: 1;
      }
    }
  }

  &.orange {
    &.fill {
      background: $c_orange;

      &:active,
      &:hover {
        background: darken($c_orange, 10%);
        opacity: 1;
      }
    }

    &.border {
      color: $c_orange;

      &:active,
      &:hover {
        border: 1px solid darken($c_orange, 10%);
        color: darken($c_orange, 10%);
        opacity: 1;
      }
    }

    &.underline {
      color: $c_orange;

      &:active,
      &:hover {
        color: darken($c_orange, 10%);
        opacity: 1;
      }
    }
  }

  &.white {
    &.fill {
      color: $c_green;
      background: #fff;

      &:active,
      &:hover {
        color: darken($c_green, 10%);
        background: darken(#fff, 10%);
        opacity: 1;
      }
    }

    &.border {
      color: #fff;

      &:active,
      &:hover {
        border: 1px solid darken(#fff, 10%);
        color: darken(#fff, 10%);
        opacity: 1;
      }
    }

    &.underline {
      color: #fff;

      &:active,
      &:hover {
        color: darken(#fff, 10%);
        opacity: 1;
      }
    }
  }

  /* Class Type */

  &.border {
    border: 1px solid currentcolor;
    background: transparent;
    transition: border .2s, color .2s;
  }

  &.underline {
    height: auto;
    padding: 0;
    border-bottom: 1px solid currentcolor;
    border-radius: 0;
    line-height: 1.6;
    background: transparent;
    transition: border .2s;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }

  &.fill,
  &.border,
  &.underline {
    /* Text colors */
    &.text-green {
      color: $c_green;
    }

    &.text-orange {
      color: $c_orange;
    }

    &.text-white {
      color: #fff;
    }
  }

  &.border-white { //@todo remove
    color: #fff;

    &:active,
    &:focus,
    &:hover {
      border: 1px solid currentcolor;
      color: #fff;
      opacity: .7;
    }

    &.size-s {
      min-width: inherit;
      padding: 0 10px;
      font-size: 12px;
      line-height: 17px;
    }

    &.size-m {
      line-height: 36px;
    }

    &.size-l {
      line-height: 46px;
    }
  }
}
